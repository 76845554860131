<template>
    <b-overlay :show="isLoading">
        <b-card>
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(onSubmit)" id="invoice-form">
					<b-row>
						<b-col class="mb-4 col-4">
							<ValidationProvider rules="required" v-slot="{ errors, valid }">
									<b-form-group
										:label="$t('type')"
										:invalid-feedback="errors[0]"
										:state="valid ? true : (errors[0] ? false : null)"
										:class="{'has-error': errors[0]}">
										<select v-model="invoice.documentType" id="document-type-select" class="form-control">
												<option value=""></option>
												<option value="Invoice">{{ $t('invoiceType') }}</option>
												<option value="CreditNote">{{ $t('creditNoteType') }}</option>
										</select>
									</b-form-group>
							</ValidationProvider>
						</b-col>
					</b-row>
                    <b-row>
                        <b-col>
                            <b-alert v-show="invoice.validationStatus === 'PENDING'" show variant="warning" style="background: #fff3cd">{{ $t('pendingPurchaseOrderCode') }}</b-alert>
                            <b-alert v-show="invoice.validationStatus === 'VALID'" show variant="success" style="background: #d4edda">{{ $t('validPurchaseOrderCode') }}</b-alert>
                            <b-alert v-show="invoice.validationStatus === 'INVALID'" show variant="dark" style="background: #f8d7da">{{ $t('invalidPurchaseOrderCode') }}</b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="col-4">
                            <b-form-group :label="$t('purchaseOrder')" class="mb-2 text-black">
                                <b-input-group-append>
                                    <b-form-input type="text" v-model="purchaseOrder"></b-form-input>
                                    <b-button variant="primary" class="col-3" @click="validatePurchaseOrderCode(purchaseOrder, 'INVOICING')" :disabled="!invoice.documentType">
                                        <font-awesome-icon icon="check"></font-awesome-icon>&nbsp;&nbsp;{{ $t('validate') }}
                                    </b-button>
                                </b-input-group-append>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4" v-if="invoice.purchaseOrder !== ''">
                        <b-col>
                            <b-alert v-show="invoice.purchaseOrder !== '' && !amounts.isSameCurrency" show variant="warning"
                                     style="background: #FFEDC8">
                                {{ $t('purchaseOrderCurrencyPart1Alert') }} (<strong>{{ amounts.currencyPO }}</strong>)
                                {{ $t('purchaseOrderCurrencyPart2Alert') }} (<strong>{{ invoice.currency }}</strong>)
                            </b-alert>
                            <b-alert show :variant="(((!amounts.canInvoice && amounts.amountExceeded) || (!amounts.canInvoice && !amounts.amountExceeded)) && invoice.documentType === 'Invoice') ? 'warning' : 'info'"
                                     :style="((!amounts.canInvoice && amounts.amountExceeded) || (!amounts.canInvoice && !amounts.amountExceeded)) && invoice.documentType === 'Invoice' ? 'background: #fff3cd' : ''">
								<p v-if="invoice.documentType === 'Invoice' && !amounts.canInvoice && !amounts.amountExceeded">{{ $t('purchaseOrderInvoicedAlert') }}</p>
                                <p v-if="invoice.documentType === 'Invoice' && !amounts.canInvoice && amounts.amountExceeded">{{ $t('amountExceeded') }}</p>
                                
                                <b-row>
                                    <b-col class="col-2">
                                        <p><strong>{{ $t('purchaseOrderAmountLabel') }}:</strong></p>
                                        <p><strong>{{ $t('amountInvoicedLabel') }}:</strong></p>
                                        <p><strong>{{ $t('invoiceAmountLabel') }}:</strong></p>
<!--                                        <p><strong>Importe total facturado:</strong></p>-->
                                    </b-col>
                                    <b-col class="col-2 text-right pr-5">
                                        <p>$ {{ formatMoney(amounts.purchaseTotal, 2, [',',',','.']) }} {{ amounts.currencyPO }}</p>
                                        <p>$ {{ formatMoney(amounts.invoicedTotal, 2, [',',',','.']) }} {{ amounts.currencyPO }}</p>
                                        <p>$ {{ formatMoney(exchangeRate(invoice.total), 2, [',',',','.']) }} {{ invoice.currency }}</p>
<!--                                        <p>$ {{ formatMoney(amounts.invoicedSum, 2, [',',',','.']) }} {{ amounts.currencyPO }}</p>-->
                                    </b-col>
                                </b-row>
                            </b-alert>
                        </b-col>
                    </b-row>
                    <b-row v-if="!user.requiredXml && !user.isForeign">
                        <b-col>
                            <b-form-group :label="$t('requiresXmlFile')">
                                <div class="switch" style="margin-top: -8px">
                                    <div class="onoffswitch">
                                        <input id="require-xml-file" type="checkbox" class="onoffswitch-checkbox" v-model="invoice.requiresXmlFile"
                                               :disabled="invoice.validationStatus !== 'VALID'" @click="requireXml">
                                        <label for="require-xml-file"  class="onoffswitch-label">
                                            <span class="onoffswitch-inner"></span>
                                            <span class="onoffswitch-switch"></span>
                                        </label>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <template v-if="!invoice.requiresXmlFile">
                        <b-row>
                            <b-col>
                                <ValidationProvider rules="requiredFile" v-slot="{ errors }">
                                     <b-form-group label="PDF / IMG" class="mb-2 text-black" :invalid-feedback="errors[0]">
                                        <b-form-file v-model="invoicePdfFIle" type="file" accept=".pdf, .jpg, .png, .jpeg" size="md"
                                                     placeholder="" id="invoice-pdf-file" :browse-text="$t('select')"
                                                     :state="errors[0] ? false : null" :disabled="disableInput()"></b-form-file>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('invoiceDate')" :invalid-feedback="errors[0]">
                                        <b-form-datepicker id="invoiceDate-datepicker" v-model="invoice.invoiceDate"
                                                           :state="errors[0] ? false : null" class="mb-2"
                                                           :disabled="disableInput()"
                                                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
<!--                                        <b-form-input type="text" v-model="invoice.invoiceDate"-->
<!--                                                      :state="errors[0] ? false : null" placeholder="YYYY-MM-DD"></b-form-input>-->
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('invoiceNumber')" :invalid-feedback="errors[0]">
                                        <b-form-input type="text" v-model="invoice.invoiceNumber" @input="validateFields"
                                                      :disabled="disableInput()"
                                                      :state="errors[0] ? false : null"></b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('amount')" :invalid-feedback="errors[0]">
                                        <b-form-input type="text" v-model="invoice.total" @input="validateFields"
                                                      :disabled="disableInput()"
                                                      :state="errors[0] ? false : null"></b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <b-form-group :label="$t('currency')">
                                    <select id="currency-select" :disabled="disableInput()">
                                        <option value=""></option>
                                    </select>
                                </b-form-group>
                            </b-col>
<!--                            <b-col v-if="invoice.purchaseOrder !== '' && !amounts.isSameCurrency">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('exchangeRate')" :invalid-feedback="errors[0]">
                                        <b-form-input type="text" v-model="invoice.exchangeRate" @input="validateFields"
                                                      :disabled="invoice.validationStatus !== 'VALID'"
                                                      :state="errors[0] ? false : null"></b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>-->
                        </b-row>
                    </template>
                    <template v-if="invoice.requiresXmlFile">
                        <b-row class="mb-2">
                            <b-col>
                                <ValidationProvider rules="requiredFile" v-slot="{ errors }">
                                    <b-form-group label="XML" class="mb-2 text-black" :invalid-feedback="errors[0]">
                                        <b-form-file v-model="invoiceXmlFIle" type="file" accept=".xml" size="md"
                                                     v-on:change="xmlFileChange" v-on:click="beforeXmlChange"
                                                     placeholder="" id="invoice-xml-file" :state="errors[0] ? false : null"
                                                     :browse-text="$t('select')"
                                                     :disabled="disableInput()"></b-form-file>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="requiredFile" v-slot="{ errors }">
                                    <b-form-group label="PDF / IMG" class="mb-2 text-black" :invalid-feedback="errors[0]">
                                        <b-form-file v-model="invoicePdfFIle" type="file" accept=".pdf, .jpg, .png, .jpeg" size="md"
                                                     placeholder="" id="invoice-pdf-file" :browse-text="$t('select')"
                                                     :state="errors[0] ? false : null"
                                                     :disabled="/*!amounts.canInvoice || */disableInput()"></b-form-file>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-if="invoice.requiresXmlFile">
                        <template v-if="invoice.UUID">
                            <b-row class="mt-4">
                                <b-col>
                                    <strong>{{ $t('serial') }}:</strong>
                                    <p class="mt-2">{{ invoice.serial }}</p>
                                </b-col>
                                <b-col>
                                    <strong>{{ $t('folio') }}:</strong>
                                    <p class="mt-2">{{ invoice.folio }}</p>
                                </b-col>
                                <b-col>
                                    <strong>{{ $t('invoiceDate') }}:</strong>
                                    <p class="mt-2">{{ invoice.invoiceDate }}</p>
                                </b-col>
                                <b-col>
                                    <strong>{{ $t('amount') }}:</strong>
                                    <p class="mt-2">$ {{ formatMoney(invoice.total, 2, [',',',','.']) }}</p>
                                </b-col>
                                <b-col>
                                    <strong>{{ $t('currency') }}:</strong>
                                    <p class="mt-2">{{ invoice.currency }}</p>
                                </b-col>
                                <b-col>
                                    <strong>{{ $t('paymentMethod') }}:</strong>
                                    <p class="mt-2">{{ invoice.paymentMethod }}</p>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col>
                                    <strong>{{ $t('paymentWay') }}:</strong>
                                    <p class="mt-2">{{ invoice.paymentWay }}</p>
                                </b-col>
                                <b-col>
                                    <strong>{{ $t('useCFDI') }}:</strong>
                                    <p class="mt-2">{{ invoice.CFDI }}</p>
                                </b-col>
                                <b-col>
                                    <strong>{{ $t('voucherType') }}:</strong>
                                    <p class="mt-2">{{ invoice.voucherType }}</p>
                                </b-col>
<!--                                <b-col>-->
<!--                                    <strong>Fecha de certificación:</strong>-->
<!--                                    <p class="mt-2">{{ invoice.certificateDate }}</p>-->
<!--                                </b-col>-->
                                <b-col :class="invoice.purchaseOrder !== '' && !amounts.isSameCurrency ? 'col-4' : 'col-6'">
                                    <strong>{{ $t('uuid') }}:</strong>
                                    <p class="mt-2">{{ invoice.UUID }}</p>
                                </b-col>
                                
                                <b-col v-if="invoice.purchaseOrder !== '' && !amounts.isSameCurrency">
                                    <strong>{{ $t('exchangeRate') }}:</strong>
                                    <p class="mt-2">{{ invoice.exchangeRate }}</p>
                                </b-col>
                            </b-row>
                        </template>
                    </template>
                    <b-row class="mt-4" v-if="invoice.issuingTaxId !== ''">
                        <b-col class="col-2">
                            <strong>{{ $t('issuingTaxId') }}:</strong>
                            <p class="mt-2">{{ invoice.issuingTaxId }}</p>
                        </b-col>
                        <b-col class="col-4">
                            <strong>{{ $t('issuingName') }}:</strong>
                            <p class="mt-2">{{ invoice.issuingName }}</p>
                        </b-col>
                        <b-col class="col-2">
                            <strong>{{ $t('recipientTaxId') }}:</strong>
                            <p class="mt-2">{{ invoice.recipientTaxId }}</p>
                        </b-col>
                        <b-col class="col-4">
                            <strong>{{ $t('recipientName') }}:</strong>
                            <p class="mt-2">{{ invoice.recipientName }}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('notes')" class="text-black">
                                <b-form-textarea
                                    id="invoice-notes"
                                    rows="3"
                                    no-auto-shrink
                                    v-model="invoice.notes"
                                    :disabled="/*!amounts.canInvoice || */disableInput()"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col class="text-right">
                            <b-button type="button" variant="outline-primary" class="ml-4" :to="{ name: 'Invoices' }">
                                <font-awesome-icon icon="times"></font-awesome-icon>&nbsp;{{ $t('cancel') }}
                            </b-button>
                            <b-button type="submit" variant="primary" class="align-content-end ml-3" form="invoice-form"
                                      :disabled="disableInput() /*|| !amounts.canInvoice*/ || !enableSendInvoice">
                                <font-awesome-icon icon="paper-plane"></font-awesome-icon>&nbsp;&nbsp;{{ $t('send') }} {{ $t('invoice').toLowerCase() }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import * as constants from '@constants';
import { formatMoney } from '@/js/utilities';
import { validatePurchaseOrder, saveInvoice, currencyList } from '@routes';
import $ from 'jquery';

export default {
    data() {
        return {
            isLoading: false,
            breadcrumb: {
                title: this.$t('registerInvoice'),
                path: []
            },
            invoiceXmlFIle: [],
            invoicePdfFIle: [],
            invoiceXmlFIleName: '',
            invoicePdfFIleName: '',
            invoice: {
                id: 0,
                folio: '',
                invoiceDate: '',
                total: 0.00,
                exchangeRate: '',
                currency: '',
                issuingName: '',
                issuingTaxId: '',
                recipientName: '',
                recipientTaxId: '',
                purchaseOrder: '',
                salesOrder: '',
                supplierToken: '',
                serial: '',
                paymentMethod: '',
                paymentWay: '',
                voucherType: '',
                CFDI: '',
                UUID: '',
                certificateDate: '',
                action: '',
                validationStatus: '',
                notes: '',
                validationCodeValidated: '',
                validationCode: '',
                dollarPrice: '',
                euroPrice: '',
                warehouseReceiptToken: '',
                warehouseReceiptValidationStatus: '',
                warehouseReceiptValidated: '',
                requiresXmlFile: true,
                invoiceNumber: '',
                documentType: '',
                supplierId: 0
            },
            amounts: {
                purchaseTotal: 0.00,
                invoicedTotal: 0.00,
                invoicedSum: 0.00,
                creditTotal: 0.00,
                currencyPO: 'USD',
                canInvoice: true,
                isSameCurrency: true,
                amountExceeded: false
            },
            user: {
                id: 0,
                name: 'Username',
                email: '',
                username: '',
                roles: '',
                isForeign: false,
                verified: false,
                status: '',
                supplierId: 0,
                supplierLegalName: '',
                reason: '',
                requiredXml: false,
                taxId: ''
            },
            purchaseOrder: '',
            purchaseOrderData: {
                id: 0,
                folio: '',
                additionalSuppliers: []
            },
            enableSendInvoice: false,
            cfdiRelated: []
        };
    },
    mounted() {
        let $this = this;
        
        extend('requiredFile', {
            ...required,
            message: $this.$t('selectFile')
        });
        
        extend('required', {
            ...required,
            message: $this.$t('requiredField')
        });
        
        this.user = JSON.parse(localStorage.getItem('user'));
        this.$emit('update-breadcrumb', this.breadcrumb);
        
        if (this.user.isForeign) {
            this.invoice.requiresXmlFile = false;
            
            this.invoice.issuingName = this.user.supplierLegalName;
            this.invoice.issuingTaxId = this.user.taxId;
            this.invoice.recipientName = 'RIJK ZWAAN PROMEX';
            this.invoice.recipientTaxId = 'RZP081105ID0';
            this.invoice.currency = 'MXN';
            this.enableSendInvoice = true;

            this.$nextTick(() => {
                setTimeout(() => { this.initSelect2(); }, 500);
            });
        }

        this.initTypeSelect2();
    },
    methods: {
        formatMoney: formatMoney,
        onSubmit() {
            let $this = this;
            this.isLoading = true;
            this.loginError = false;
            
            this.$swal({
                title: `¡${ $this.$t('attention') }!`,
                text: $this.$t('msgActionInvoice').replace('ACTION', $this.$t('send').toLowerCase()),
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: $this.$t('cancel'),
                confirmButtonColor: '#3fbf0d',
                confirmButtonText: $this.$t('yes')
            }).then(confirm => {
                if (confirm.isConfirmed) {
                    let formData = new FormData();

                    if (!this.invoice.requiresXmlFile) {
                        this.updateVoucherType();
                    }

                    this.invoice.supplierId = this.user.supplierId;
                    
                    formData.append('invoice', JSON.stringify(this.invoice));
                    formData.append('purchaseOrderData', JSON.stringify(this.purchaseOrderData));
                    formData.append('cfdiRelatedData', JSON.stringify(this.cfdiRelated));
                    formData.append('action', 'CREATE');
                    formData.append('user', JSON.stringify(this.user));
                    formData.append('invoiceXmlFIle', this.invoiceXmlFIle);
                    formData.append('invoicePdfFIle', this.invoicePdfFIle);
                    
                    this.axios.post(saveInvoice(), formData).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast($this.$t('invoiceSent'), {
                                title: '',
                                variant: 'success'
                            });
                            
                            setTimeout(() => { this.$router.push({ name: 'Invoices' }); }, 1500);
                        } else if (response.data.message) {
                            this.$bvToast.toast($this.$t('invoiceRegistered'), {
                                title: '',
                                variant: 'danger'
                            });
                            
                            this.isLoading = false;
                        } else {
                            this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                                title: '',
                                variant: 'danger'
                            });
                            
                            this.isLoading = false;
                        }
                    }).catch(() => {
                        this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                            title: '',
                            variant: 'danger'
                        });
                        
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            });
        },
        clearXmlData() {
            this.invoice.folio = '';
            this.invoice.invoiceDate = '';
            this.invoice.total = 0.00;
            this.invoice.currency = '';
            this.invoice.issuingName = '';
            this.invoice.issuingTaxId = '';
            this.invoice.recipientName = '';
            this.invoice.recipientTaxId = '';
            this.invoice.serial = '';
            this.invoice.paymentMethod = '';
            this.invoice.paymentWay = '';
            this.invoice.voucherType = '';
            this.invoice.CFDI = '';
            this.invoice.UUID = '';
            this.invoice.invoiceNumber = '';
            this.invoice.certificateDate = '';
            this.invoiceXmlFIleName = '';
            this.$forceUpdate();
        },
        requireXml() {
            this.invoiceXmlFIle = [];
            this.invoicePdfFIle = [];
            this.amounts.canInvoice = true;
            this.amounts.isSameCurrency = true;
            this.amounts.amountExceeded = false;
            this.clearXmlData();
            
            if (this.invoice.requiresXmlFile) {
                this.invoice.issuingName = this.user.supplierLegalName;
                this.invoice.issuingTaxId = this.user.taxId;
                this.invoice.recipientName = 'RIJK ZWAAN PROMEX';
                this.invoice.recipientTaxId = 'RZP081105ID0';
                this.invoice.currency = this.amounts.currencyPO;
                
                this.$nextTick(() => {
                    setTimeout(() => { this.initSelect2(); }, 500);
                });
            }
        },
        beforeXmlChange() {
            this.invoiceXmlFIle = [];
            this.clearXmlData();
        },
        xmlFileChange() {
            let $this = this;
            let $xmlFile = $('#invoice-xml-file');
            
            $this.isLoading = true;
            $this.clearXmlData();
            
            if ($xmlFile.val() !== '') {
                try {
                    let fileReader = new FileReader();

                    $this.enableSendInvoice = false;

                    fileReader.onload = function(e) {
                        try {
                            let parser = new DOMParser();
                            let xmlDoc = parser.parseFromString(e.target.result, 'text/xml');
                            
                            if (xmlDoc != null && $(xmlDoc).find('cfdi\\:Comprobante').length > 0) {
                                if ($(xmlDoc).find('cfdi\\:Comprobante')[0] !== undefined && $(xmlDoc).find('cfdi\\:Emisor')[0] !== undefined
                                    && $(xmlDoc).find('cfdi\\:Receptor')[0] !== undefined && $(xmlDoc).find('tfd\\:TimbreFiscalDigital')[0] !== undefined) {
                                    let isValidDate = true;
                                    let cfdiRelatedList = $(xmlDoc).find('cfdi\\:CfdiRelacionados');
                                    let cfdiRelated = [];

                                    for (let i = 0; i < cfdiRelatedList.length; i++) {
                                        const cfdiRelacionados = cfdiRelatedList[i];
                                        const tipoRelacion = cfdiRelacionados.getAttribute('TipoRelacion');

                                        const cfdiRelacionadoList = cfdiRelacionados.getElementsByTagName('cfdi:CfdiRelacionado');

                                        for (let j = 0; j < cfdiRelacionadoList.length; j++) {
                                            const uuid = cfdiRelacionadoList[j].getAttribute('UUID');

                                            cfdiRelated.push({
                                                cfdiRelacionadosIndex: i + 1,
                                                cfdiRelacionadoIndex: j + 1,
                                                tipoRelacion: tipoRelacion,
                                                uuid: uuid
                                            });
                                        }
                                    }

                                    $this.cfdiRelated = cfdiRelated;
                                    
                                    $.each($(xmlDoc).find('cfdi\\:Comprobante')[0].attributes, function(index, object) {
                                        let invoiceDate;
                                        let currentDate;
                                        
                                        switch (object.name.toLowerCase()) {
                                            case 'folio':
                                                $this.invoice.folio = object.value;
                                                break;
                                            case 'moneda':
                                                $this.invoice.currency = object.value;
                                                break;
                                            case 'total':
                                                $this.invoice.total = object.value;
                                                break;
                                            case 'metodopago':
                                                $this.invoice.paymentMethod = object.value;
                                                break;
                                            case 'tipodecomprobante':
                                                $this.invoice.voucherType = object.value;
                                                break;
                                            case 'formapago':
                                                $this.invoice.paymentWay = object.value;
                                                break;
                                            case 'serie':
                                                $this.invoice.serial = object.value;
                                                break;
                                            case 'tipocambio':
                                                $this.invoice.exchangeRate = object.value;
                                                break;
                                            case 'fecha':
                                                invoiceDate = new Date(object.value);
                                                currentDate = new Date();
                                                
                                                if (invoiceDate.getFullYear() === currentDate.getFullYear() && (invoiceDate.getMonth() + 1) === (currentDate.getMonth() + 1)) {
                                                    $this.invoice.invoiceDate = invoiceDate.getFullYear() + '-' +
                                                        ((invoiceDate.getMonth() + 1) <= 9 ? '0' + (invoiceDate.getMonth() + 1) : (invoiceDate.getMonth() + 1)) + '-' +
                                                        (invoiceDate.getDate() <= 9 ? '0' + invoiceDate.getDate() : invoiceDate.getDate()) + ' ' +
                                                        (invoiceDate.getHours() <= 9 ? '0' + invoiceDate.getHours() : invoiceDate.getHours()) + ':' +
                                                        (invoiceDate.getMinutes() <= 9 ? '0' + invoiceDate.getMinutes() : invoiceDate.getMinutes()) + ':' +
                                                        (invoiceDate.getSeconds() <= 9 ? '0' + invoiceDate.getSeconds() : invoiceDate.getSeconds());

                                                } else {
                                                    isValidDate = false;
                                                }
                                                break;
                                        }
                                    });
                                    
                                    $.each($(xmlDoc).find('cfdi\\:Emisor')[0].attributes, function(index, object) {
                                        switch (object.name.toLowerCase()) {
                                            case 'rfc':
                                                $this.invoice.issuingTaxId = object.value;
                                                break;
                                            case 'nombre':
                                                $this.invoice.issuingName = object.value;
                                                break;
                                        }
                                    });
                                    
                                    $.each($(xmlDoc).find('cfdi\\:Receptor')[0].attributes, function(index, object) {
                                        switch (object.name.toLowerCase()) {
                                            case 'rfc':
                                                $this.invoice.recipientTaxId = object.value;
                                                break;
                                            case 'nombre':
                                                $this.invoice.recipientName = object.value;
                                                break;
                                            case 'usocfdi':
                                                $this.invoice.CFDI = object.value;
                                                break;
                                        }
                                    });
                                    
                                    $.each($(xmlDoc).find('tfd\\:TimbreFiscalDigital')[0].attributes, function(index, object) {
                                        let certificateDate;

                                        switch (object.name.toLowerCase()) {
                                            case 'uuid':
                                                $this.invoice.UUID = object.value;
                                                break;
                                            case 'fechatimbrado':
                                                certificateDate = new Date(object.value);

                                                $this.invoice.certificateDate = certificateDate.getFullYear() + '-' +
                                                    ((certificateDate.getMonth() + 1) <= 9 ? '0' + (certificateDate.getMonth() + 1) : (certificateDate.getMonth() + 1)) + '-' +
                                                    (certificateDate.getDate() <= 9 ? '0' + certificateDate.getDate() : certificateDate.getDate()) + ' ' +
                                                    (certificateDate.getHours() <= 9 ? '0' + certificateDate.getHours() : certificateDate.getHours()) + ':' +
                                                    (certificateDate.getMinutes() <= 9 ? '0' + certificateDate.getMinutes() : certificateDate.getMinutes()) + ':' +
                                                    (certificateDate.getSeconds() <= 9 ? '0' + certificateDate.getSeconds() : certificateDate.getSeconds());

                                                break;
                                        }
                                    });

                                    if ($this.invoice.issuingTaxId !== $this.user.taxId) {
                                        $this.$bvToast.toast($this.$t('taxIdDifferent'), {
                                            title: '',
                                            variant: 'danger'
                                        });
                                        
                                        $this.invoiceXmlFIle = [];
                                        $this.clearXmlData();
                                    }
                                    
                                    if ($this.invoice.recipientTaxId !== constants.COMPANY_TAX_ID) {
                                        $this.$bvToast.toast($this.$t('invalidTaxId'), {
                                            title: '',
                                            variant: 'danger'
                                        });
                                        
                                        $this.invoiceXmlFIle = [];
                                        $this.clearXmlData();
                                    }
                                    
                                    if (!isValidDate) {
                                        $this.$bvToast.toast($this.$t('onlyCurrentMonth'), {
                                            title: '',
                                            variant: 'danger'
                                        });
                                        
                                        $this.invoiceXmlFIle = [];
                                        $this.clearXmlData();
                                    }
                                    
                                    if ($this.invoice.paymentMethod === 'PPD' && $this.invoice.paymentWay !== '99') {
                                        $this.$bvToast.toast($this.$t('differentPaymentMethod'), {
                                            title: '',
                                            variant: 'danger'
                                        });
                                        
                                        $this.invoiceXmlFIle = [];
                                        $this.clearXmlData();
                                    }

                                    if ($this.invoice.voucherType !== 'I' && $this.invoice.voucherType !== 'E') {
                                        $this.$bvToast.toast($this.$t('invalidVoucherType'), {
                                            title: '',
                                            variant: 'danger'
                                        });

                                        $this.invoiceXmlFIle = [];
                                        $this.clearXmlData();
                                    } else if (($this.invoice.documentType === 'Invoice' && $this.invoice.voucherType !== 'I') ||
										($this.invoice.documentType === 'CreditNote' && $this.invoice.voucherType !== 'E')) {
                                        $this.$bvToast.toast($this.$t('invalidVoucherDocumentType'), {
                                            title: '',
                                            variant: 'danger'
                                        });

                                        $this.invoiceXmlFIle = [];
                                        $this.clearXmlData();

                                    }

                                    if ($this.invoice.purchaseOrder !== '') {
                                        if ($this.amounts.currencyPO === $this.invoice.currency) {
                                            $this.amounts.isSameCurrency = true;
                                        } else {
                                            $this.amounts.isSameCurrency = false;
                                        }

                                        if (parseFloat($this.invoice.total) >= 0 && ($this.amounts.isSameCurrency)) {
                                            let sumInvoiced = $this.amounts.invoicedTotal + parseFloat($this.invoice.total);
                                            $this.amounts.invoicedSum = sumInvoiced;

                                            let maxPOTotal = $this.amounts.purchaseTotal + parseFloat(`${ constants.INTEGER_ACCEPTED_RANGE_WHEN_INVOICING }.${ constants.DECIMAL_ACCEPTED_RANGE_WHEN_INVOICING }`);
                                            let intPOTotal = Math.floor($this.amounts.purchaseTotal);
                                            let intInvoiceTotal = Math.floor(sumInvoiced);

                                            if (intInvoiceTotal === intPOTotal) {
                                                $this.amounts.canInvoice = true;
                                                $this.amounts.amountExceeded = false;
                                            } else if (intInvoiceTotal > intPOTotal) {
                                                if ((sumInvoiced >= $this.amounts.purchaseTotal) && (sumInvoiced <= maxPOTotal)) {
                                                    $this.amounts.canInvoice = true;
                                                    $this.amounts.amountExceeded = false;
                                                } else {
                                                    $this.amounts.canInvoice = false;
                                                    $this.amounts.amountExceeded = true;
                                                }
                                            } else if (intInvoiceTotal < intPOTotal) {
                                                $this.amounts.canInvoice = true;
                                                $this.amounts.amountExceeded = false;
                                            }
                                        }
                                    }

                                    if ($this.invoice.UUID && $this.invoice.issuingName && $this.invoice.issuingTaxId) {
                                        $this.enableSendInvoice = true;
                                    } else {
                                        $this.$bvToast.toast($this.$t('invalidFileFormat'), {
                                            title: '',
                                            variant: 'danger'
                                        });
                                    }
                                } else {
                                    $this.$bvToast.toast($this.$t('invalidFileFormat'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                }
                            } else {
                                $this.$bvToast.toast($this.$t('invalidFileFormat'), {
                                    title: '',
                                    variant: 'danger'
                                });
                            }
                        } catch (exp) {
                            $this.$bvToast.toast($this.$t('invalidFileFormat'), {
                                title: '',
                                variant: 'danger'
                            });
                        }
                    };
                    
                    fileReader.readAsText($xmlFile[0].files[0]);
                } catch (e) {
                    $this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                        title: '',
                        variant: 'danger'
                    });
                    
                    $this.isLoading = false;
                } finally {
                    $this.isLoading = false;
                }
            } else {
                $this.isLoading = false;
            }
        },
        validatePurchaseOrderCode(code, type) {
            let $this = this;
            this.amounts= {
                purchaseTotal: 0.00,
                invoicedTotal: 0.00,
                invoicedSum: 0.00,
                creditTotal: 0.00,
                currencyPO: 'USD',
                canInvoice: true,
                isSameCurrency: true,
                amountExceeded: false
            };
            this.invoiceXmlFIle = [];
            this.invoicePdfFIle = [];
            this.purchaseOrderData = [];
            
            if (!this.user.isForeign) {
                this.clearXmlData();
            }
            
            this.isLoading = true;
            
            this.axios.post(validatePurchaseOrder(), {
                validationCode: code.trim(),
                type: type,
                supplierId: this.user.supplierId
            }).then(response => {
                this.isLoading = false;
                
                if (response.data.code === 'OK') {
                    if (type === 'INVOICING') {
                        if (response.data.isValid === true) {
                            if (response.data.POTotal) {
                                this.amounts.purchaseTotal = parseFloat(response.data.POTotal);
                            }
                            
                            if (response.data.invoicedTotal) {
                                this.amounts.invoicedTotal = parseFloat(response.data.invoicedTotal);
                            }
                            
                            if (response.data.currencyPO) {
                                this.amounts.currencyPO = response.data.currencyPO;
                            }
                            
                            if (response.data.POTotal && response.data.invoicedTotal) {
                                
                                if (this.amounts.invoicedTotal > 0) {
                                    let intPOTotal = this.amounts.purchaseTotal;
                                    let intInvoiceTotal = this.amounts.invoicedTotal;
                                    
                                    if (intInvoiceTotal === intPOTotal) {
                                        this.amounts.canInvoice = false;
                                        this.beforeXmlChange();
                                    } else if (intInvoiceTotal > intPOTotal) {
                                        this.amounts.canInvoice = false;
                                        this.beforeXmlChange();
                                    } else {
                                        this.amounts.canInvoice = true;
                                    }
                                } else {
                                    this.amounts.canInvoice = true;
                                }
                            }
                            
                            if (response.data.purchaseOrder) {
                                this.purchaseOrderData = response.data.purchaseOrder;
                            }
                            
                            this.invoice.purchaseOrder = code;
                            this.invoice.validationStatus = 'VALID';
                            this.invoice.validationCodeValidated = this.invoice.validationCode;
                        } else {
                            this.invoice.validationStatus = 'INVALID';
                            this.invoice.validationCodeValidated = '';
                        }
                    }
                } else {
                    this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                        title: '',
                        variant: 'danger'
                    });
                }
            }).catch(() => {
                this.$bvToast.toast($this.$t('DEFAULT_ERROR_MESSAGE'), {
                    title: '',
                    variant: 'danger'
                });
                
                this.isLoading = false;
            });
        },
        initSelect2() {
            let $currency = $('#currency-select');
            let $this = this;
            
            $currency.select2({
                placeholder: $this.$t('currency'),
                language: {
                    noResults: () => $this.$t('noResultsFound')
                },
                ajax: {
                    url: currencyList(),
                    dataType: 'json',
                    method: 'GET',
                    data: function(params) {
                        return {
                            searchWord: params.term
                        };
                    },
                    processResults: function(data) {
                        return { results: data };
                    }
                },
                escapeMarkup: markup => markup,
                templateResult: function(currency) {
                    if (currency.loading) {
                        return $this.$t('searching') + "... <i class='fa fa-spinner fa-pulse fa-fw pull-right'></i>";
                    }
                    
                    return currency.code || currency.text;
                },
                templateSelection: function(currency) {
                    if (currency.code === undefined && currency.text === undefined) {
                        return '';
                    }
                    
                    return currency.code || currency.text;
                }
            }).on('select2:select', e => {
                this.invoice.currency = e.params.data.code;
                this.validateFields();
            });
            
            if (this.invoice.currency) {
                this.axios.get(currencyList()).then(response => {
                    let currencySelected = response.data.find(currency => currency.code === $this.invoice.currency);
                    
                    if (currencySelected) {
                        $currency.empty().append(new Option(currencySelected.code, currencySelected.id, true, true)).trigger('change');
                    }
                });
            }
            
        },
        initTypeSelect2() {
            let $type = $('#document-type-select');
            let $this = this;

            $type.select2({
                placeholder: '',
                allowClear: false,
                minimumResultsForSearch: -1
            }).on('select2:select', function() {
                $this.invoice.documentType = $(this).val();

                if ($this.invoice.documentType === 'Invoice') {
                    $this.invoice.validationStatus = 'PENDING';
                } else {
                    $this.invoice.validationStatus = 'OK';
                }

                $this.clearData();
            });
        },
        validateFields() {
            let $this = this;
            
            if ($this.invoice.purchaseOrder !== '') {
                if ($this.amounts.currencyPO === $this.invoice.currency) {
                    $this.amounts.isSameCurrency = true;
                } else {
                    $this.amounts.isSameCurrency = false;
                }
                
                /*if (parseFloat($this.invoice.total) >= 0) {
                    let invoiceAmount = this.exchangeRate($this.invoice.total);
                    let sumInvoiced = $this.amounts.invoicedTotal + parseFloat(invoiceAmount);
                    $this.amounts.invoicedSum = sumInvoiced;
                    
                    let maxPOTotal = $this.amounts.purchaseTotal + parseFloat(`${ constants.INTEGER_ACCEPTED_RANGE_WHEN_INVOICING }.${ constants.DECIMAL_ACCEPTED_RANGE_WHEN_INVOICING }`);
                    let intPOTotal = Math.floor($this.amounts.purchaseTotal);
                    let intInvoiceTotal = Math.floor(sumInvoiced);
                    
                    if (intInvoiceTotal === intPOTotal) {
                        $this.amounts.canInvoice = true;
                        $this.amounts.amountExceeded = false;
                    } else if (intInvoiceTotal > intPOTotal) {
                        if ((sumInvoiced >= $this.amounts.purchaseTotal) && (sumInvoiced <= maxPOTotal)) {
                            $this.amounts.canInvoice = true;
                            $this.amounts.amountExceeded = false;
                        } else {
                            $this.amounts.canInvoice = false;
                            $this.amounts.amountExceeded = true;
                        }
                    } else if (intInvoiceTotal < intPOTotal) {
                        $this.amounts.canInvoice = true;
                        $this.amounts.amountExceeded = false;
                    }
                }*/
            }
        },
        exchangeRate(total) {
            if (!this.amounts.isSameCurrency) {
                if (this.amounts.currencyPO === 'MXN') {
                    total = total * (this.invoice.exchangeRate ? this.invoice.exchangeRate : 1);
                } else if (this.amounts.currencyPO === 'USD') {
                    total = total / (this.invoice.exchangeRate ? this.invoice.exchangeRate : 1);
                }
            }
            
            return total;
        },
        updateVoucherType() {
            let $this = this;

            $this.invoice.voucherType = $this.invoice.documentType === 'Invoice' ? 'I' : 'E';
        },
        clearData() {
            this.clearXmlData();

            this.purchaseOrder = '';
            this.invoiceXmlFIle = [];
            this.invoicePdfFIle = [];

            if (!this.invoice.requiresXmlFile) {
                this.invoice.issuingName = this.user.supplierLegalName;
                this.invoice.issuingTaxId = this.user.taxId;
                this.invoice.recipientName = 'RIJK ZWAAN PROMEX';
                this.invoice.recipientTaxId = 'RZP081105ID0';
                this.invoice.currency = 'MXN';
            }

            this.$forceUpdate();
        },
        disableInput() {
            return this.invoice.validationStatus === 'INVALID' || this.invoice.validationStatus === 'PENDING' || this.invoice.validationStatus === '';
        }
    },
    watch: {
    
    },
    computed: {
    
    }
};
</script>
<style>
    .repairs-filter {
        flex-wrap: unset;
    }
</style>